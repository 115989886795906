.dropdown {
	position: relative;
	
	.dropdown-btn {
		.dropdown-icon {
			display: flex;
			margin-left: 0.4em;
			height: 0.4em;
			width: 0.4em;
		}
	}
	
	.dropdown-body {
		position: absolute;
		top: calc(100% + 10px);
		background-color: $white;
		box-shadow: $shadow;
		border-radius: $radiusSmall;
	}
}