.contact {
	padding-bottom: 180px;
	
	.contact-confirm {
		
		.title-big {
			margin-bottom: 20px;
		}
		
		.subtitle {
			max-width: 1000px;
			margin-bottom: 20px;
			
			&.info {
				margin-bottom: 80px;
			}
		}
		
		.link {
			font-size: 22px;
		}
	}
	
	form {
		margin-top: 60px;
		width: 60vw;
		
		.form-row {
			width: 100%;
			
			.input-text,
			.input-email {
				width: calc(50% - 25px);
				
				input {
					width: 100%;
				}
			}
		
			.input-textarea {
				margin-top: 50px;
				
				textarea {
					width: 100%;
				}
			}
			
			&.error {
				margin-top: 20px;
				margin-bottom: -10px;
			}
			
			&.captcha {
				margin-top: 20px;
			}
		}
		
		.subtitle {
			margin-top: 10px;
		}
		
		
		.submit-container {
			margin-top: 30px;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.contact {
		padding-bottom: 80px;
		
		.title-big {
			font-size: 11vw;
		}
		
		.contact-confirm {
			.subtitle:not(.info) {
				font-size: 22px;
			}
		}
		
		form {
			width: 100%;
			margin-top: 30px;
			
			.form-row {
				flex-direction: column;
				.input-text,
				.input-email {
					width: 100%;
					margin-bottom: 20px;
				}
				
				.input-textarea {
					margin-top: 0;
				}
			}
			
			.submit-container {
				flex-direction: row;
			}
		}
	}
}


@media only screen and (max-width: 800px) {
	.contact {
		form {
			.submit-container {
				flex-direction: column;
				
				.toggle {
					margin-bottom: 40px;
				}
			}
		}
	}
}


@media only screen and (max-width: 400px) {
	.contact {
		.title-big {
			font-size: 36px;
		}
	}
}