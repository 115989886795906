.anchor {
	position: relative;
	color: $main;
	font-size: 18px;
	
	&.anchor-up svg {
		bottom: calc(100% + 0.2em);
		transform: rotate(-90deg);
	}
	
	&.anchor-down svg {
		top: calc(100% + 0.2em);
		transform: rotate(90deg);
	}

	svg {
		position: absolute;
		left: calc(50% - 0.5em);
	}
}