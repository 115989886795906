.technologies {
	position: relative;
	margin: 0 -#{$pagePadding};
	background-color: black;
	align-items: flex-start;
	min-height: 450px;
	
	img,
	video {
		width: 100%;
	}
	
	.app-ad-body {
		position: absolute;
		bottom: 10%;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0 40px;
		
		.title-big {
			font-size: 5vw;
			color: white;
			max-width: 80vw;
		}
		
		.subtitle {
			margin-top: 2.5vw;
			font-size: 22px;
			color: $grey;
			max-width: 1000px;
		}
		
		.link {
			margin-top: 20px;
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 700px) {
	.app-ad {
		.app-ad-body {
			padding: 0 $pagePadding;
			
			.title-big {
				font-size: 25px;
			}
		}
	}
}