.domains {
	margin: 0 -#{$pagePadding};
	background-color: $veryLight;
	padding: 80px 40px;
	
	.domain {
		width: calc(100% / 3);
		min-width: calc(100px + 160px);
		box-sizing: border-box;
		padding: 80px;
		
		.icon-container {
			height: 150px;
			width: 150px;
			margin-bottom: 40px;
			color: $main;
		}
		
		.domain-body {
			text-align: center;
			
			.title {
				font-size: 40px;
				font-weight: normal;
			}
			
			.subtitle {
				margin-top: 30px;
				font-size: 20px;
			}
		}
	}
}

@media only screen and (max-width: 1100px) {
	.domains {
		.domain {
			width: calc(100% / 2);
		}
	}
}

@media only screen and (max-width: 900px) {
	.domains {
		padding: 20px $pagePadding;
		
		.domain {
			width: 100%;
			padding: 40px 0;
			
			.icon-container {
				margin-bottom: 20px;
			}
		}
	}
}