.page-footer {
	padding-top: 200px;
	padding-bottom: 100px;
	
	.subtitle {
		margin-top: 20px;
		margin-bottom: 40px;
		max-width: 900px;
	}
}

@media only screen and (max-width: 600px) {
	.page-footer {
		.title-big {
			font-size: 36px;
		}
	}

}