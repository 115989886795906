// Normal
@font-face {
	font-family: 'LexendDeca';
	font-style: normal;
	font-weight: normal;
	src: url('/res/fonts/LexendDeca-Regular.ttf') format('truetype');
}

// Bold
@font-face {
	font-family: 'LexendDeca';
	font-style: normal;
	font-weight: bold;
	src: url('/res/fonts/LexendDeca-SemiBold.ttf') format('truetype');
}