.project {
	
	.project-header {
		margin-right: -$pagePadding;
		overflow: hidden;
		
		.project-header-left {
			width: 45%;
			padding-top: 40px;
			box-sizing: border-box;
			position: relative;
			z-index: 1;
			
			.link {
				font-size: 18px;

				svg {
					transform: scaleX(-1)
				}
			}
			
			.title-big {
				margin-top: 20px;
				font-size: 90px;
			}
			
			.subtitle {
				font-size: 22px;
				margin-top: 40px;
				width: 80%;
				
				&.date {
					margin-top: 20px;
					font-size: 18px;
				}
			}
			
			.btn {
				margin-top: 40px;
				display: inline-block;
			}
		}
		
		.project-header-right {
			width: 55%;
			min-width: 700px;
			box-sizing: border-box;
			
			&.away {
				padding-right: $pagePadding;
			}
			
			span{
				width: 100%;
				
				img {
					width: 100%;
				}
			}
		}
		
	}
	
	.project-body {
		.project-image {
			margin-top: 150px;
			
			> img {
				max-width: 100%;
				border-radius: $radiusSmall;
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.project {
		.project-header {
			margin-left: -$pagePadding;
			
			.project-header-cont {
				flex-direction: column;
				
				.project-header-left {
					width: 100%;
					padding-right: $pagePadding;
					padding-left: $pagePadding;
					
					.subtitle {
						width: auto;
					}
					
					.title-big {
						font-size: 10vw;
					}
				}
				
				.project-header-right {
					order: -1;
					width: 100%;
					min-width: auto;
					padding-left: 8vw;
					box-sizing: border-box;
					
					.img-cont {
						display: flex;
					}
					
					img {
						width: 100%;
					}
				}
				
			}
		}
	}
}