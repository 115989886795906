// Override default browser style

body, li, p, ul, h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

button, a, input, textarea {
	outline-style: none;
}

button, input {
	background: transparent;
	border: none;
}

textarea {
	resize: none;
	border: none;
}

// Selection
a, .btn, .tag, img, svg, button, .dropdown-btn, label {
	user-select: none;
}

::selection {
	background-color: transparentize($main, 0.85);
}

::-moz-selection {
	background-color: transparentize($main, 0.85);
}

// Scroll bar
::-webkit-scrollbar-thumb {
	background-color: $lightGrey;
}

::-webkit-scrollbar-thumb:hover {
	background-color: $grey;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: transparent;
}


