.error404 {
	padding-top: 10vh;
	
	.subtitle {
		margin-top: 20px;
		font-size: 40px;
		
		&.info {
			font-size: 16px;
			margin-bottom: 80px;
		}
	}
	
	.link {
		font-size: 22px;
	}
}


@media only screen and (max-width: 1000px) {
	.error404 {
		
		.title-big {
			font-size: 11vw;
		}
		
		.subtitle:not(.info) {
			font-size: 24px;
		}
	}
}

@media only screen and (max-width: 400px) {
	.error404 {
		
		.title-big {
			font-size: 36px;
		}
	}
}
