$modalHeaderHeight: 60px;
.modal-container {
	> :not(.modal) {
		cursor: pointer;
	}
	
	.modal {
		
		&.open {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 6;
			overflow: auto;
			background-color: $black;
			box-sizing: border-box;
			padding: 5vw;
			
			.modal-content {
				.icon-container {
					cursor: pointer;
					position: absolute;
					top: 2vw;
					right: 2vw;
					height: 2vw;
					width: 2vw;
					color: $white;
				}
				
				.modal-body {
					width: 100%;
					height: 100%;
					
					img {
						max-width: 100%;
						max-height: 100%;
						width: auto;
						height: auto;
					}
				}
			}
			
		}
	}
}
