.btn {
	background-color: $main;
	color: $white;
	padding: 0.6em 2em;
	transition: all 100ms linear;
	border-radius: $radiusSmall;
	font-size: 18px;
	box-sizing: border-box;
	
	&:hover, &:focus {
		background-color: $mainDark;
		color: $white;
	}
	
	&.inactive {
		box-shadow: inset 0 0 0 1px $main;
		background-color: transparent;
		color: $main;
	}
}