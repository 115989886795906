// Colors
$main: #0068FF;
//$main: #00FF89;
$mainLight: lighten($main, 40%);
$mainDark: darken($main, 10%);
$black: #2C2C2C;
$white: white;
$grey: #868686;
$darkGrey: #737272;

$error: #F1473C;

$lightGrey: #D0D0D0;
$veryLight: #FAFAFC;

// Variables
$pagePadding: 8vw;

$navbarHeight: 50px;
$navbarBigHeight: 120px;

$radiusSmall: 8px;
$radius: 15px;

$shadow: 0 0 20px rgba(0,0,0,0.1);


// Night mode
/*
$black: white;
$white: #2C2C2C;

$lightGrey: #252525;
$veryLight: #252525;
*/