.big-list {
	.big-list-item {
		margin-top: 100px;
		
		.item-image {
			margin-right: 50px;
			//border-radius: 100%;
			overflow: hidden;
			height: 200px;
			width: 200px;
			display: flex;
			box-shadow: 0 0 0 transparent;
			transition: all 200ms ease-out;
			
			img {
				max-width: 100%;
				max-height: 100%;
			}
			
			&:hover {
				box-shadow: $shadow;
			}
		}
		
		.item-content {
			width: calc(100% - 200px - 50px);
			
			.subtitle {
				font-size: 20px;
				max-width: 60%;
				display: block;
			}
			
			.item-header {
				margin-bottom: 20px;
				
				.item-title {
					margin-bottom: 10px;
					
					.title {
						font-size: 40px;
						font-weight: normal;
					}
					
					.date {
						font-size: 30px;
						margin-left: 40px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.big-list {
		padding: 20px 0;
		
		.big-list-item {
			margin-top: 60px;
			flex-direction: column;
			align-items: center;
			
			.item-image {
				margin-right: 0;
				margin-bottom: 30px;
			}
			
			&:first-child {
				margin-top: 0;
			}
			
			.item-content {
				width: 100%;
				
				.subtitle {
					max-width: none;
				}
				
				.item-header {
					.item-title {
						flex-direction: column;
						align-items: flex-start;
						
						.date {
							margin-top: 20px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}