.about {
	
	.list-skill {
		margin: 0 -#{$pagePadding};
		padding: 120px $pagePadding;
		background-color: $veryLight;
	}
	
	h2.title-big {
		margin-bottom: 40px;
	}
}