.about-header {
	min-height: calc(100vh - #{$navbarBigHeight} - 20px);
	padding-bottom: $navbarBigHeight;
	box-sizing: border-box;
	position: relative;
	
	.img-container {
		background-color: $veryLight;
		height: 50vh;
		width: 50vh;
		
		img {
			width: 100%;
			border-radius: $radius;
			overflow: hidden;
		}
	}
	
	.title-container {
		margin-left: 100px;
		
		.title-big {
			font-size: 8vw;
			line-height: 1em;
			margin-bottom: 20px;
			
			+ .subtitle {
				color: $black;
				font-size: 4vw;
				margin-bottom: 40px;
			}
		}
	}
	
	.anchor {
		position: absolute;
		bottom: calc($navbarBigHeight * 2 / 3);
		right: 0;
	}
}

@media only screen and (max-width: 1000px) {
	.about-header {
		
		.title-container {
			margin-left: 50px;
		}
	}
}

@media only screen and (max-width: 800px) {

	.about-header {
		min-height: auto;
		flex-direction: column;
		
		.img-container {
			background-color: $veryLight;
			width: 50vw;
			height: 50vw;
			min-width: 250px;
			min-height: 250px;
			margin-bottom: 30px;
		}
		
		.title-container {
			margin-left: 0;
			
			.title-big {
				font-size: 12vw;
				
				+ .subtitle {
					font-size: 25px;
				}
			}
			
		}
	}
}