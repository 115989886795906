.input {
	position: relative;
	box-sizing: border-box;
	
	input, textarea {
		border: 1.5px solid $veryLight;
		background-color: $veryLight;
		border-radius: $radiusSmall;
		padding: 1em 1.5em;
		line-height: 1.2em;
		box-sizing: border-box;
		
		&:required {
			box-shadow: none;
		}
		
		&:focus,
		&.filled {
			border-color: $main;
			background-color: $white;
			
			+ label {
				top: -0.6em;
				left: 1em;
				font-size: 16px;
				color: $main;
				background-color: $white;
			}
		}
		
		&.filled:invalid {
			border-color: $error;
			
			+ label {
				color: $error;
			}
		}
	}
	
	label {
		position: absolute;
		top: calc(1em / 2 + 1em - 0.6em);
		left: calc(1.5em - 4px);
		background-color: $veryLight;
		padding: 0 4px;
		font-size: 18px;
		color: $grey;
		transition: all 100ms ease-out;
		cursor: text;
	}
}