$toggleHeight: 2em;
$toggleWidth: 3.2em;
$toggleSpace: 0.2em;

.toggle {
	height: $toggleHeight;
	
	input {
		display: none;
	}
	
	input:checked + label {
		color: $black;
		
		&::before {
			background-color: $main;
		}
		
		&::after {
			top: $toggleSpace;
			left: $toggleWidth - $toggleHeight + $toggleSpace;
		}
	}
	
	label {
		height: 100%;
		cursor: pointer;
		padding-left: $toggleWidth + 1em;
		position: relative;
		transition: all 150ms ease-out;
		color: $grey;
		
		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: calc($toggleHeight / 2);
			transition: all 150ms ease-out;
		}
		
		&::before {
			top: 0;
			left: 0;
			width: $toggleWidth;
			height: $toggleHeight;
			background-color: $lightGrey;
		}
		
		&::after {
			top: $toggleSpace;
			left: $toggleSpace;
			width: 1.6em;
			height: 1.6em;
			background-color: $white;
		}
	}
}