.list-project {
	.list-project-header {
		.list-tag {
			margin-top: 40px;
		}
		margin-bottom: 60px;
	}
	
	.list-project-body {
		.project-box {
			width: 100%;
			
			&:nth-child(even) .info-container {
				order: -1;
				padding-left: 0;
				padding-right: 100px;
			}
			
			.img-viewer {
				width: 35vw;
				height: 35vw;
				
				.img-container {
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					
					img {
						max-height: 100%;
						max-width: 100%;
						border-radius: 15px;
						overflow: hidden;
					}
				}
			}
			
			.info-container {
				box-sizing: border-box;
				width: calc(100% - 35vw);
				padding: 50px 0 50px 100px;
				color: $grey;
				font-size: 20px;
				
				.title {
					color: $black;
					margin-bottom: 20px;
				}
				
				.list-tag {
					margin-bottom: 20px;
					
					.tag-item:not(:last-child) {
						
						&::after {
							content : ' — ';
						}
					}
				}
				
				.description {
					color: $grey;
					margin-bottom: 20px;
				}
				
				.date {
					font-size: 16px;
				}
				
				.link-container {
					margin-top: 40px;
				}
			}
			
			&:not(:last-child) {
				margin-bottom: 20vh;
			}
		}
	}
	
	.list-project-footer {
		margin-top: 120px;
		
		.subtitle {
			margin-top: 20px;
			margin-bottom: 40px;
			max-width: 900px;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.list-project {
		.list-project-body {
			.project-box {
				.info-container {
					.title {
						font-size: 6vw;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.list-project {
		.list-project-header {
			.title-big {
				font-size: 14vw;
			}
		}
		
		.list-project-body {
			
			.project-box {
				flex-direction: column;
				
				&:nth-child(even) .info-container {
					order: 0;
					padding: 0;
				}
				
				.img-viewer {
					width: 100%;
					height: auto;
					margin-bottom: 30px;
					
					.img-container {
						width: 100%;
						height: auto;
						
						
						img {
							width: 100%;
							height: auto;
						}
					}
				}
				
				.info-container {
					padding: 0;
					width: auto;
					
					.title {
						font-size: 12vw;
					}
				}
			}
			
		}
	}
}