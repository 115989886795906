.topic {
	border-bottom: 1px solid $lightGrey;
	
	&:first-child {
		border-top: 1px solid $lightGrey;
	}
	
	.topic-header {
		cursor: pointer;
		padding: 40px 0;

		&:hover .topic-header-cont {
			color: $main
		}
		
		.topic-header-cont {
			width: calc(100% - 72px);
			
			.icon-container {
				width: 50px;
				height: 40px;
				margin-right: 50px;
				color: $black;
				
				img {
					max-width: 100%;
					max-height: 100%;
					color: $black;
				}
				
			}
			
			.text-cont {
				width: calc(100% - 50px - 50px);
			}
			
			.title {
				font-size: 22px;
			}
			
			.subtitle {
				margin-top: 10px;
				font-size: 18px;
			}
		}
		
		.btn-container {
			width: 22px;
			margin-right: 50px;
			
			&.down {
				transform: rotate(180deg);
			}
		}
	}
	
	.topic-body {
		color: $grey;
		padding: 0 100px 0 100px;
		font-size: 18px;
		
		table {
			td:first-child {
				padding-right: 100px;
				display: block;
				white-space: nowrap;
			}
			
			td {
				padding-bottom: 40px;
			}
		}
	}
}


@media only screen and (max-width: 1000px) {
	.topic {
		
		.topic-header {
			.topic-header-cont {
				width: calc(100% - 42px);
				
				.icon-container {
					margin-right: 30px;
				}
				
				.text-cont {
					width: calc(100% - 50px - 30px);
				}
			}
			
			.btn-container {
				margin-right: 0;
			}
		}
		
		.topic-body {
			padding: 0;
			
			table {
				td:first-child {
					padding-right: 20px;
				}
			}
		}
	}
}