// General styles
body {
	overflow-x: hidden;
}

body, button, input, textarea {
	background-color: $white;
	color: $black;
	font-size: 16px;
	font-family: 'LexendDeca', sans-serif;
}

main {
	box-sizing: border-box;
	margin-top: $navbarBigHeight;
	padding: 20px $pagePadding;
	min-height: calc(100vh - #{$navbarBigHeight});
}



.section-dark {
	background-color: $black;
}

// Buttons & links
a {
	color: $black;

	&:hover {
		color: $main;
	}
	
	&.strong {
		transition: all 100ms linear;
		
		&:hover, &:focus {
			color: $mainDark;
		}
	}
}

a, .link, .tag, .btn, button {
	cursor: pointer;
}

// Text
strong, .strong, .subtitle.strong {
	font-weight: inherit;
	color: $main;
}

// Tag
.tag {
	border-radius: 2em;
	padding: 0.5em 1em;
	color: $grey;
	background-color: $veryLight;
	transition: all 100ms ease-out;
	cursor: pointer;
	display: inline-block;
	font-size: 18px;
	margin-bottom: 15px;
	
	&:not(:last-child) {
		margin-right: 25px;
	}
	
	&:hover {
		background-color: $mainLight;
		color: $main
	}
	
	&.active {
		background-color: $main;
		color: $white;
	}
}

.error {
	color: $error
}

// Image
img {
	&.full {
		box-shadow: $shadow;
	}
}
