.animated-text {
	.text-pointer {
		font-weight: normal;
		color: $grey;
		
		&.waiting {
			animation: blink 1000ms infinite;
		}
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.00001% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}