.home-header {
	min-height: calc(100vh - #{$navbarBigHeight});
	box-sizing: border-box;
	padding-bottom: 40px;
	
	.home-header-body {
		width: 100%;
		
		.home-title {
			font-size: 7vw;
			line-height: 1.05;
		}
		
		.subtitle {
			margin-top: 40px;
			font-size: 2.5vw;
		}
		
		.link-container {
			margin-top: 40px;
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 900px) {
	.home-header {
		min-height: auto;
		
		.home-header-body {
			.subtitle {
				margin-top: 20px;
				font-size: 20px;
			}
		}
	}
}