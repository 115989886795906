.footer {
	padding: 70px 0;
	background-color: $veryLight;
	font-size: 18px;
	
	.footer-col {
		margin: 30px 60px;
		
		h2 {
			font-size: 32px;
		}
		
		h3 {
			font-size: 20px;
		}
		
		h2, h3 {
			margin-bottom: 24px;
			height: 28px;
			display: flex;
			align-items: flex-end;
		}
		
		li {
			margin-top: 15px;

			a {
				display: flex;
				align-items: center;

				svg {
					height: 20px;
					width: 20px;
					margin-right: 5px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	footer.footer {
		.footer-body {
			flex-direction: column;
			align-items: flex-start;
			
			.footer-col-cont {
				width: 100%;
				justify-content: space-between;
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	footer.footer {
		
		.footer-col-cont {
			flex-direction: column;
		}
	}
}