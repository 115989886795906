// Navbar

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $navbarHeight;
	background-color: $white;
	white-space: nowrap;
	box-sizing: border-box;
	padding: 0 40px;
	font-size: 16px;
	transition: all 200ms ease-out;
	z-index: 5;
	
	.btn-wrapper {
		display: none;
		transition: all 200ms ease-out;
	}
	
	&.navbar-small {
		box-shadow: 0 0 20px rgba(0,0,0,0.1);
	}
	
	&.navbar-big,
	&.navbar-open {
		height: 120px;
		font-size: 20px;
		box-shadow: 0 0 20px rgba(0,0,0,0);
		
		.logo-container {
			margin-left: 40px;
			height: 40px;
		}
	}
	
	
	.logo-container {
		height: 20px;
		transition: height 200ms ease-out, margin-left 200ms ease-out;
		
		.logo {
			margin-right: 1em;
			color: $main;
		}
	}
	
	.list-link {
		
		.link-item {
			margin-right: 60px;
			cursor: pointer;
		
			a {
				transition: none;
			}
			
			&.theme-picker {
				cursor: pointer;
				height: 1em;
				width: 1em;
				border-radius: 100%;
				background-color: $white;
				border: 2px solid $black;
				
				&:hover {
					border-color: $grey;
					background: $black;
				}
			}
			
			.list-language {
				padding: 0.4em 0;
				
				.language {
					padding: 0.5em 1em;
				}
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	
	.navbar, .navbar.navbar-big, .navbar.navbar-small  {
		&.navbar-small:not(.navbar-open) {
			.btn-wrapper {
				padding: 8px;
				left: 10px;
			}
		}
		
		.btn-wrapper {
			display: flex;
			width: 40px;
			height: 40px;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: calc(50% - 20px);
			left: 40px;
			cursor: pointer;
			box-sizing: border-box;
		}
		
		&.navbar-open {
			.navbar-content {
				.navbar-list-cont {
					right: 0;
				}
			}
			
			+ main {
				overflow: hidden;
			}
		}
		
		.navbar-content {
			justify-content: center;
			
			.logo-container {
				margin-left: 0;
			}
			
			.navbar-list-cont {
				position: fixed;
				top: 0;
				right: 100%;
				background-color: white;
				height: 100%;
				width: 100%;
				z-index: -1;
				padding: 120px 40px;
				box-sizing: border-box;
				overflow-y: auto;
				transition: all 200ms ease-out;
				
				ul {
					flex-direction: column;
					
					li {
						font-size: 12vw;
						
						a {
							display: block;
							width: 100%;
						}
						
						&:not(:last-child) {
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.navbar.navbar-big,
	.navbar.navbar-open {
		.navbar-content {
			.btn-wrapper {
				height: 25px;
				width: 25px;
				top: calc(50% - 25px / 2)
			}
			
			.logo-container {
				height: 25px;
				margin-left: 60px;
			}
		}
	}
}