h1, h2, h3, h4, h5, h6, .title {
	font-weight: bold;
}

h1.title-big {
	font-size: 110px;
}

h2.title-big {
	font-size: 90px;
	word-break: break-word;
}

h3.title-big {
	font-size: 60px;
}

.subtitle {
	color: $grey;
}

h1.title-big + .subtitle {
	font-size: 2.5vw;
}

h3.title-big + .subtitle {
	font-size: 20px;
}