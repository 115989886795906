.experiences {
	padding-top: 150px;
	
	.panel {
		.title-big {
			text-align: center;
			margin-top: 40px;
		}
	}
}

@media only screen and (max-width: 900px) {
	.experiences {
		.btn-group .btn {
			font-size: 15px;
		}
		
		.panel {
			.title-big {
				font-size: 12vw;
			}
		}
	}
}