.feedback {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	transition: all 200ms ease-out;
	
	&.hidden {
		transform: translateY(100%);
	}
	
	.feedback-body {
		background-color: $white;
		box-shadow: $shadow;
		padding: 20px 40px;
		border-top-right-radius: $radius;
		border-top-left-radius: $radius;
		font-size: 18px;
		
		.icon-container {
			height: 25px;
			width: 25px;
			margin-left: 30px;
			cursor: pointer;
			color: $grey;
			transition: all 100ms linear;
			
			&:hover {
				color: $main;
			}
			
			&.reverse {
				transform: scaleY(-1);
				
				&:hover {
					color: $error;
				}
			}
			
			&.icon-close {
				height: 20px;
				width: 20px;
				color: $grey;
			}
		}
		
		.link {
			margin-left: 0.25em;
		}
	}
}