// Flex
.flex {
  display: flex;

  &.flex-center {
    align-items: center;
    justify-content: center;
  }

  &.flex-separate {
    justify-content: space-between;
  }
  
  &.flex-end {
    justify-content: flex-end;
  }
  
  &.flex-bottom {
    align-items: flex-end;
  }
  
  &.flex-center-vert:not(.flex-col) {
    align-items: center;
  }
  
  &.flex-center-hori:not(.flex-col) {
    justify-content: center;
  }
  
  &.flex-start:not(.flex-col) {
    justify-content: flex-start;
  }

  // Directions
  &.flex-row {
    flex-direction: row;
  }
  
  &.flex-wrap {
    flex-wrap: wrap;
  }
  
  &.flex-col {
    flex-direction: column;

    &.flex-center-vert {
      justify-content: center;
    }

    &.flex-center-hori {
      align-items: center;
    }
  
    &.flex-start {
      align-items: flex-start;
    }
  }
}

// Size
.full {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

// Margin
.margin-right-1 {
  margin-right: 0.5em;
}

.margin-left-1 {
  margin-left: 0.5em;
}

// Others
.center {
  text-align: center;
}


